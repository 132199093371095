import './App.css';
import { loginRequest, msalConfig, msalInstance } from './authConfig';
import Homepage from './Views/Homepage';
import { useIsAuthenticated } from "@azure/msal-react";
import Cookies from "universal-cookie";
import React from "react";
function App() {

  const [isValid, setValid] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const isSsoAuthenticated = useIsAuthenticated();

  let isAuthenticated = false;
  const cookies = new Cookies();
  //check if the user is authenticated
  var checked = false

  //check valid email
  const getEmail = () => {
    let doms = ["@gucci.com", "@it.gucci.com", "@garpe.it", "@piginicalzature.it", "@gt\-{1}srl.it", "@gpasrl.it", "@tigerflex.it", "@gjpsrl.it", "@uk.gucci.com", "@us.gucci.com"];
    let regex = ''
    let found = false
    return new Promise(function (resolve, reject) {
      msalInstance.acquireTokenSilent({
        ...loginRequest,
        ...msalConfig.auth.authority,
        account: msalInstance.getAllAccounts()[0]
      }).then((response) => {
        doms.map((dom) => {
          regex = new RegExp('^.*(' + dom + ')$')
          if (regex.test(response.account.username)) {
            found = true
            resolve(true)
          }
        })
      }).catch(function (error) {
        reject(false)
      }).finally(() => {
        if (!found)
          resolve(false)
      });
    })
  }


  if (cookies.get('user_token') === undefined) {
    if (isSsoAuthenticated) {

      cookies.set('user_token', 'token', { path: '/', maxAge: 31536000 });
      isAuthenticated = true;
      checked = true
      getEmail().then((response) => {
        setValid(response)
        setLoading(false)

      })
    } else {
      getEmail().then((response) => {
        setValid(response)
      })
      msalInstance.loginRedirect(loginRequest).catch(e => {
        console.log(e)
      })
    }
  } else {
    checked = true
    isAuthenticated = true;
    getEmail().then((response) => {
      setValid(response)
    })
  }

  /* const validEmail = (email) => {
   
     if (!trovata) {
       return false
     }
     else
       return true
   
   }*/


  return (
    <div className="App">
      {isAuthenticated && isValid ? <Homepage /> :
        (!isAuthenticated && !loading) || (!isValid && !loading) ? <div className='not_auth'>Unauthorized</div> : <div className='spinner_cont'>
          <div className="spinner"></div>
        </div>
      }
    </div>
  );
}

export default App;
