import Header from "./Header";
import Navbar from "./Navbar";
import React from "react";
import ScrollContainer from 'react-indiana-drag-scroll';
import $ from "jquery"
export default function Homepage(props) {

    const [selected, setSelected] = React.useState(0)
    const [hideLeftArrow, setHideLeftArrow] = React.useState(true)
    const [hideRightArrow, setHideRightArrow] = React.useState(window.innerWidth > 1200 ? true : false)
    const [navbarMenuSelected, setNavbarMenuSelected] = React.useState(0)


    const selectAndScroll = (value) => {
        // setNavbarMenuSelected(value)
        let section = ''
        switch (value) {
            case 0:
                section = '#about_gucci';
                break;
            case 1:
                section = '#the_learning_experience';
                break;
            case 2:
                section = '#the_platform';
                break;
            case 3:
                section = '#the_team';
                break;
            case 4:
                section = '#coming_soon';
                break;
            default:
                break;
        }
        $('html,body').animate({
            scrollTop: $(section).offset().top - 150
        },
            'slow');

    }

    window.addEventListener("scroll", (event) => {
        let about_gucci = ''
        let learning_experience = ''
        let platform = ''
        let the_team = ''
        let coming_soon = ''
        if (document.getElementById('about_gucci') && document.getElementById('the_learning_experience') && document.getElementById('the_platform') && document.getElementById('coming_soon')) {
            about_gucci = document.getElementById('about_gucci').getBoundingClientRect()
            learning_experience = document.getElementById('the_learning_experience').getBoundingClientRect()
            platform = document.getElementById('the_platform').getBoundingClientRect()
            /*   the_team = document.getElementById('the_team').getBoundingClientRect()*/
            coming_soon = document.getElementById('coming_soon').getBoundingClientRect()
            if (about_gucci.top > 0 && about_gucci.top < window.innerHeight) {
                setNavbarMenuSelected(0)
            }
            else if (learning_experience.top > 0 && learning_experience.top < window.innerHeight) {
                setNavbarMenuSelected(1)
            }
            else if (platform.top > 0 && platform.top < window.innerHeight) {
                setNavbarMenuSelected(2)
            }
            /*  else if (the_team.top > 0 && the_team.top < window.innerHeight) {
                  setNavbarMenuSelected(3)
              }*/
            else if (coming_soon.top > 0 && coming_soon.top < window.innerHeight) {
                setNavbarMenuSelected(4)
            }
        }
    })
    const checkArrow = () => {


        let a = document.getElementsByClassName('cards_container')
        //check left arrow
        if (a[0] && a[0].scrollLeft == 0)
            setHideLeftArrow(true)
        else
            setHideLeftArrow(false)

        //check right arrow
        if (a[0] && a[0].scrollLeft + window.innerWidth == 1220 || window.innerWidth > 1200)
            setHideRightArrow(true)
        else
            setHideRightArrow(false)
    }
    const scrollLeft = () => {
        let a = document.getElementsByClassName('cards_container')
        if (a[0].scrollLeft > 100)
            a[0].scrollLeft = a[0].scrollLeft - 100
        else {
            a[0].scrollLeft = 0
        }
        checkArrow()
    }
    const scrollRight = () => {
        let a = document.getElementsByClassName('cards_container')
        if (a[0].scrollLeft < 1120)
            a[0].scrollLeft = a[0].scrollLeft + 100
        else {
            a[0].scrollLeft = 1220
        }

        checkArrow()
    }
    return (
        <React.Fragment>
            <Header />
            <div className="homepage">
                <img className="w-100 web_section" src={require('../resources/img/first_section.png')} />
                <img className="w-100 mobile_section" src={require('../resources/img/first_section_s.png')} />
                {/*GUCCI IDEAS SECTION */}
                <div id='about_gucci'>
                    <div className="white_shadow">About Guccideas</div>
                    <div className="black_text">About Guccideas</div>
                    {/*}  <div className="black_sub_text">Mission</div>*/}
                    <div className="info_text_left">AT GUCCI WE STRONGLY BELIEVE IN INNOVATION. INNOVATION IS PART OF OUR DNA AND IS A KEY VALUE FOR OUR SUSTAINABLE FUTURE. GUCCIDEAS IS OUR INNOVATION ENABLER. </div>
                    <div className="hr">
                        <div className="hr_cont"><div className="hr_left"></div></div>
                        <div className="hr_cont_right"><div className="hr_right"></div></div>
                    </div>
                    <div>
                        <div className="hr_down">
                            <div className="hr_cont"><div className="points_class"></div></div>
                            <div className="hr_cont_right">
                                <div className="black_sub_text_right">UNLEASHING OUR COLLECTIVE INNOVATIVE POTENTIAL</div>
                                <div className="info_text_right">Each of us is unique and brings a new perspective. We want to connect all our 22,000 minds to share their diverse vision, skills, experience, and resources and think about innovative solutions that will allow us to be able to cope with ever-changing reality and keep at the forefront of luxury fashion.
                                </div>
                                <br /> <div className="info_text_right">
                                    With GUCCIDEAS, we want to nurture a culture of innovation by tapping into the brain power of the entire Gucci community and unleashing the collective innovative potential to keep our work environment as collaborative and creative as possible.</div></div>
                        </div>
                    </div>
                </div>

                {/*the learnign experience*/}
                <div className="black_section" id='the_learning_experience'>

                    <div className="white_sub_text">The learning experience</div>

                    <div className="info_text_left_white">A learning program focused on the fundamentals of innovation and on new methods to foster creative thinking. A journey through digital modules and activities, aimed at making innovation a habit. </div>
                    <div className="hr">
                        <div className="hr_cont"><div className="hr_left_white"></div></div>
                        <div className="hr_cont_right"><div className="hr_right_white"></div></div>
                    </div>
                    <div>
                        <div className="hr">
                            <div className="hr_cont"><div className="black_space"></div></div>
                            <div className="hr_cont_right">
                                <div className="info_text_right_white">
                                    The experience consists of 5 digital modules together with individual and collaborative activities to discover how innovation works, how to cultivate the art of observation and develop new ideas, stimulating the creative thinking that is part of our DNA and that is nurtured by the diversity and uniqueness of the people who make up our Gucci community.
                                </div>
                                <br />
                                <div className="info_text_right_white grid">
                                    {/*   <b className="link_button link_button_white link_button_le"><a target="_blank" href="https://retailacademyportal.gucci.com/pages/292/guccideas" style={{ color: "black" }}>Check if there is an available seat (Retail)</a></b>
                                    <b className="link_button link_button_white link_button_le"><a target="_blank" href="https://eu.degreed.com/plan/640189?orgsso=gucci" style={{ color: "black" }}>Check if there is an available seat (Office)</a></b>
    */} </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*the platform*/}
                <div id='the_platform'>


                    <div>
                        <div className="hr">
                            <div className="hr_cont"><div className="hr_right_down"></div></div>
                            <div className="hr_cont_right_down">
                                <div className="white_right_shadow">The platform</div>
                                <div className="black_sub_text_r">The platform</div>
                                <br />
                                <div className="info_text_right">
                                    A place for innovation, ideas, inspiration. A place to collaborate and unleash our collective innovative potential.</div>
                            </div>

                        </div>
                        <div className="hr" Style={"margin-top: -20px;"}>
                            <div className="hr_cont"><div className="hr_left"></div></div>
                            <div className="hr_cont_right"><div className="hr_right"></div></div>
                        </div>
                        <div>
                            <div className="hr">
                                <div className="hr_cont_right">
                                    <div className="info_text">The platform is accessible to everyone and everywhere and allows us to address business challenges and propose solutions, tapping into the brain and connecting more than 22,000 voices. Each challenge can be activated either globally, locally, or for a specific audience, providing flexibility, autonomy, and favoring experimentation.
                                    </div>
                                    <br /> <div className="info_text " Style={'padding-bottom: 25px;'}>
                                        <a target="_blank" href="https://teams.microsoft.com/l/entity/32ff4322-f35e-46ec-a517-71173f222ca9/index">
                                            <b className="link_button link_button_black" >  Click here to explore the platform </b></a> </div></div>
                            </div>
                        </div>
                    </div>

                </div>

                {/*       <div id='coming_soon'>
                    <div className="white_shadow">The platform</div>
                    <div className="black_text">The platform</div>
                    <div className="info_text_left">A place for innovation, ideas, inspiration. A place to collaborate and unleash our collective innovative potential.

                    </div>
                    <div className="hr">
                        <div className="hr_cont"><div className="hr_left"></div></div>
                        <div className="hr_cont_right"><div className="hr_right"></div></div>
                    </div>
                    <div>
                        <div className="hr">
                            <div className="hr_cont"><div className="points_class"></div></div>
                            <div className="hr_cont_right">
                                <div className="info_text_right">   The platform is accessible to everyone and everywhere and allows us to address business challenges and propose solutions, tapping into the brain and connecting more than 22,000 voices. Each challenge can be activated either globally, locally, or for a specific audience, providing flexibility, autonomy, and favoring experimentation.
                                </div>
                                <br /> <div className="info_text_right">
                                    Check available missions and archive (link) </div>
                            </div>
                        </div></div>

                </div>
*/}
                {/*}  <div id='the_team'>
                    <div className="black_sub_text">The team</div>

                    <div className="info_text_left" Style={'margin-top:80px'}> We are here to facilitate and help unlocking organizational excellence and innovation from within and elevate an intrapreneurship culture in Gucci at a global scale.</div>
                    <div className="hr">
                        <div className="hr_cont"><div className="hr_left"></div></div>
                        <div className="hr_cont_right"></div>
                    </div>

                    <ScrollContainer className="cards_container" onScroll={() => checkArrow()}>

                        <div className="the_team_cards_container">

                            <div className="campaign_card">
                                <div className="valeria_icon campaign_icon" ></div>
                                <div className="campaign_card_title">
                                    Valeria Cicino
                                </div>
                                <div className="campaign_card_text">
                                    Global Collaborative  <br /> Innovation Director
                                </div>
                            </div>
                            <div className="campaign_card">
                                <div className="elisa_icon campaign_icon" ></div>
                                <div className="campaign_card_title">
                                    Elisa Cammillacci
                                </div>
                                <div className="campaign_card_text">
                                    Global Collaborative  <br /> Innovation Specialist
                                </div>
                            </div>
                            <div className="campaign_card">
                                <div className="hadi_icon campaign_icon"></div>
                                <div className="campaign_card_title">
                                    Hadi Al Souki
                                </div>
                                <div className="campaign_card_text">
                                    Global Collaborative <br /> Innovation Manager
                                </div>
                            </div>


                        </div>

                    </ScrollContainer >
                    {window.innerWidth < 1300 ? <React.Fragment>
                        {!hideLeftArrow ? <div onClick={() => scrollLeft()} className="left_campaign_arrow"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" class="bi bi-chevron-left pt-10" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg></div> : ''}
                        {!hideRightArrow ? <div onClick={() => scrollRight()} className="right_campaign_arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="white" class="bi bi-chevron-right pt-10" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div> : ''}
                    </React.Fragment> : ''}

                </div>

                    */}

                {/* HISTORY SECTION */}
                <div className="black_section " id="coming_soon" Style={'padding-bottom:100px'}>
                    <div className="white_sub_text">Coming Soon</div>

                    <div className="info_text_left_white">Your next great idea could be just around the corner. </div>
                    <div className="hr">
                        <div className="hr_cont"><div className="hr_left_white"></div></div>
                        <div className="hr_cont_right"><div className="hr_right_white"></div></div>
                    </div>
                    <div>
                        <div className="hr">
                            <div className="hr_cont"><div className="black_space"></div></div>
                            <div className="hr_cont_right">
                                <div className="info_text_right_white coming_soon_text">
                                    New things are coming <br />      Stay Tuned!  </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>



                {/*The platform */}
                {/* <div id='coming_soon'>
                    <div className="white_shadow">Coming Soon</div>
                    <div className="black_text">Coming Soon</div>
                    <div className="black_sub_text">section</div>
                    <div className="info_text_left">LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT. AENEAN EUISMOD BIBENDUM LAOREET.

                    </div>
                    <div className="hr">
                        <div className="hr_cont"><div className="hr_left"></div></div>
                        <div className="hr_cont_right"><div className="hr_right"></div></div>
                    </div>
                    <div>
                        <div className="hr">
                            <div className="hr_cont"><div className="points_class"></div></div>
                            <div className="hr_cont_right">
                                <div className="info_text_right">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.
                                </div>
                                <br /> <div className="info_text_right">
                                    Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.</div></div>
                        </div>
                    </div>

                </div>
*/}

            </div>
            <Navbar selected={navbarMenuSelected} handleChangeMenu={selectAndScroll} />
        </React.Fragment>)
}