export default function Navbar(props) {

    let menu_selected_class = "navbar_menu navbar_menu_selected"
    let menu_class = "navbar_menu"
    return (
        <div id='navbar' className="navbar" >
            <div className={props.selected == 0 ? menu_selected_class : menu_class} onClick={() => props.handleChangeMenu(0)}><div>About Guccideas</div></div>

            <div className={props.selected == 1 ? menu_selected_class : menu_class} onClick={() => props.handleChangeMenu(1)}><div>The Learning Experience</div></div>
            <div className={props.selected == 2 ? menu_selected_class : menu_class} onClick={() => props.handleChangeMenu(2)}><div>The Platform</div></div>
            {/*} <div className={props.selected == 3 ? menu_selected_class : menu_class} onClick={() => props.handleChangeMenu(3)}><div>The Team</div></div>*/}
            <div className={props.selected == 4 ? menu_selected_class : menu_class} onClick={() => props.handleChangeMenu(4)}><div>Coming Soon</div></div>
        </div>)
}